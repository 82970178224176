import { ProductLine, Invoice } from './types'

export const initialProductLine: ProductLine = {
  description: '',
  quantity: '1',
  rate: '0.00',
}

export const initialInvoice: Invoice = {
  logo: '',
  logoWidth: 100,
  title: 'Arve',
  companyName: '',
  name: '',
  companyAddress: '',
  companyAddress2: '',
  companyCountry: '',
  billTo: 'Arve saaja',
  clientName: '',
  clientAddress: '',
  clientAddress2: '',
  clientCountry: '',
  invoiceTitleLabel: 'Arve nr',
  invoiceTitle: '',
  invoiceDateLabel: 'Arve kuupäev',
  invoiceDate: '',
  invoiceDueDateLabel: 'Maksetähtaeg',
  invoiceDueDate: '',
  productLineDescription: 'Toode/Teenus',
  productLineQuantity: 'Kogus',
  productLineQuantityRate: 'Summa',
  productLineQuantityAmount: 'Kokku',
  productLines: [
    {
      description: 'Toode/Teenus',
      quantity: '1',
      rate: '100.00',
    },
  ],
  subTotalLabel: 'Summa KM-ta 22%',
  taxLabel: 'KM (22%)',
  totalLabel: 'Arve kokku',
  currency: '€',
  notesLabel: '',
  notes: '',
  term: "",
  termLabel: "",
  bank1: "",
  bank1IBAN: "",
  bank1SWIFT: "",
  bank2: "",
  bank2IBAN: "",
  bank2SWIFT: "",
  bank3: "",
  bank3IBAN: "",
  bank3SWIFT: "",
  firmaLisainfo: "",
}
